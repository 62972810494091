<template>
  <div
    v-for="(product, index) in products"
    :key="index"
    class="results--available__item"
    :id="`draggable-item-${boardIndex}-${index}`"
    :data-id="product.recordId"
    :data-board-index="boardIndex"
    :data-furniture-index="index"
  >
    <button class="results--available__item__deselect" @click="deselect">Deselect</button>
    <div
      class="results--available__item__container"
      @mousedown="handleMousedown"
      @mouseup="handleMouseup(product)"
    >
      <img
        v-if="product.cloudinaryImages && product.cloudinaryImages[0]"
        class="results--available__item__image"
        :src="product.cloudinaryImages[0].url"
      >
      <header class="results--available__item__header">
        <h2
          v-if="product.vendor && product.vendor.name"
          class="results--available__item__header__brand"
        >
          {{ product.vendor.name }}
        </h2>
        <h3 class="results--available__item__header__name">{{ product.name }}</h3>
      </header>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResultsItems',
  emits: ['deselect', 'view'],
  props: {
    products: {
      type: Array,
      default: () => []
    },
    boardIndex: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      dragTimeout: null,
      dragging: false
    }
  },
  methods: {
    // Using a timeout between mousedown and mouseup to differentiate between clicking and dragging
    handleMousedown () {
      this.dragTimeout = setTimeout(() => {
        this.dragging = true
      }, 300)
    },
    handleMouseup (product) {
      if (!this.dragging) {
        this.$emit('view', product.recordId)
      }
      clearTimeout(this.dragTimeout)
      this.dragging = false
    },
    deselect (event) {
      var parent = event.target.parentElement
      parent.style.transform = 'translate(0px, 0px)'
      parent.setAttribute('data-x', 0)
      parent.setAttribute('data-y', 0)
      parent.classList.remove('selected')
      this.$emit('deselect')
    }
  }
}

</script>

<style lang="scss" scoped>
.results--available__item__container {
  cursor: pointer;
}
.dragging .results--available__item a {
  pointer-events: none;
}

.results--available__item__header__name {
  &::after {
    content: '';
    display: inline-block;
    width: var(--leading);
    height: var(--leading);
    background: url('../../assets/images/info.svg') center center no-repeat;
    background-size: 1em 1em;
    line-height: 0;
    vertical-align: -.3em;
  }
}
</style>
