<template>
  <main class="main page newproject newCheckout">
    <div class="checkout">
      <section class="section" v-if="currentOrderComplete">
        <h1 class="section__heading">Checkout</h1>
        <h2 class="section__subheading--order-complete">Thank you. Your order is complete!</h2>
      </section>
      <form class="form" v-else>
        <section class="section">
          <h2
            v-if="targetSurvey && targetSurvey.projectName"
            class="checkout__project-name"
          >
            {{ targetSurvey.projectName }}
          </h2>
          <h1 class="section__heading">Checkout</h1>
          <Modal
            v-if="showStandardShippingModal"
            confirmLabel="Take me to the FAQ!"
            centered
            @confirm="router().push({ path: '/support', hash: '#shipping' })"
            @close="showStandardShippingModal = false"
            :fromTimeMixPopup=true
          >
            <template v-slot:header>
              <button
                class="modal__close time_mix_popup_close"
                type="button"
                aria-label="Close modal"
                @click="close()"
              >
                Close
              </button>
              Shipping information
            </template>
            <template v-slot:body>
              <span>To learn more about how we calculate shipping rates check out the </span><router-link to="/support#shipping">FAQ</router-link><span>!</span>
            </template>
          </Modal>

          <Modal
            v-if="showWhiteGloveModal"
            confirmLabel="Got it"
            centered
            @confirm="showWhiteGloveModal = false"
            @close="showWhiteGloveModal = false"
            :fromTimeMixPopup=true
          >
            <template v-slot:header>
              <button
                class="modal__close time_mix_popup_close"
                type="button"
                aria-label="Close modal"
                @click="closeWhiteGlove()"
              >
                Close
              </button>
              White Glove Service
            </template>
            <template v-slot:body>
              <p>{{ shownWhiteGloveShippingDescription }}</p>
            </template>
          </Modal>

          <ul class="checkout__items">
            <li class="checkout__items__item" v-for="(item, index) in chosenFurnitureData" :key="index">
              <div class="checkout__items__item__image">
                <img
                  v-if="item.cloudinaryImages && item.cloudinaryImages[0]"
                  :src="item.cloudinaryImages[0].url"
                >
              </div>
              <header class="checkout__items__item__details">
                <h3
                  v-if="item.vendor && item.vendor.name"
                  class="checkout__items__item__details__brand"
                >
                  {{ item.vendor.name }}
                </h3>
                <h2 class="checkout__items__item__details__product-name">{{ item.name }}</h2>
                <div class="checkout__items__item__details__controls">
                  <div class="form__item form__item--text quantity-cal">
                    <label :for="'quantity-' + index" >Quantity</label>
                    <input
                      v-model="item.quantity"
                      type="number"
                      name="quantity"
                      :id="'quantity-' + index"
                      placeholder="Quantity"
                      min="1"
                      @change="quantityUpdated(index, item.quantity)"
                    >
                  </div>
                  <div class="form__item">
                    <button class="form__item--remove red"
                      :data--item-index="index"
                      :data--item-record-id="item['Record ID']"
                      :data--item-sku="item.SKU"
                      :data--item-airtable-user-id="item.airtableUserId"
                      :data--item-firestore-user-id="item.firestoreUserId"
                      :data--item-selections-id="item.selectionsId"
                      @click.prevent="atStripeUpdateOrder('removeLineItem', index)">
                      Remove
                    </button>
                  </div>
                </div>
              </header>
              <div class="checkout__items__item__price">
                {{ formatCurrency(item.itemPrice) }}
                <div
                  class="checkout__items__item__shipping"
                >
                  <div class="checkout__items__item__shipping__item">
                    <template v-if="item.shipping.whiteGlovePrice">
                      <label>White Glove Service: ${{ item.shipping.whiteGlovePrice }}</label>
                      <input
                        v-model="shippingToggles[item.recordId]"
                        type="radio"
                        value="white-glove"
                        @click="updateWhiteGloveShipping(item)"
                      >
                      <button
                        v-if="item.shipping && item.shipping.whiteGloveDescription"
                        class="checkout__items__item__shipping__info info"
                        @click="setWhiteGloveShippingModal(item.shipping.whiteGloveDescription)"
                        type="button"
                      >
                        Info
                      </button>
                    </template>
                    <template v-else-if="item.vendor.name==='Room & Board '">
                      <label>White Glove Service included!</label>
                    </template>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <footer class="checkout__footer">
            <div class="checkout__footer__content">
              <div class="checkout__footer__total-container">
                <Loader :visible="loadingPrice" blocking :opacity="0" />

                <div class="shipping-information">
                  <div class="shipping-information--row">
                  <p>Subtotal</p>
                  <p>{{ formatCurrency(subtotal) }}</p>
                  </div>
                  <template v-if="discount > 0">
                    <div class="shipping-information--row">
                    <p>Discount</p>
                    <p>-{{ formatCurrency(discount) }}</p>
                    </div>
                  </template>
                  <div class="shipping-information--row">
                    <div class="shipping-information--shipping-description">
                  <p>Shipping</p>
                  <button
                    class="info"
                    @click="setStandardShippingModal('open')"
                    type="button"
                  >
                    Info
                  </button>
                    </div>
                    <p>{{ formatCurrency(shippingCost) }}</p>
                  </div>

                  <!-- <dt>Taxes</dt>
                  <dd>{{ formatCurrency(taxes) }}</dd> -->
                </div>
                <div class="checkout__footer__total">{{formatCurrency(total)}}</div>
              </div>
              <hr>
              <div class="checkout__footer__inputs">
                <!-- <div class="checkout__footer__inputs__cc">
                  <h2 class="checkout__subheading">
                    Credit Card
                  </h2>
                  <form id="stripe-payment-form" style="grid-column: span 10">
                  <div id="stripe-payment-element"></div>
                  <div id="affirm-message"></div>
                  </form>
                </div> -->

                <div class="checkout__footer__inputs__shipping">
                  <div class="text new_form_style">
                    <h2 class="checkout__subheading">
                      Delivery Address
                    </h2>
                    <div>
                      <div class="form__item form__item--text">
                        <input
                          id="mailing-name" type="text"
                          :placeholder="'Recipient Name'"
                          v-model="currentOrderUserInfo.shipping_details.name"
                          @input="updateValue"
                        >
                      </div>

                      <div class="form__item form__item--text">
                        <input
                          id="mailing-email"
                          type="text"
                          placeholder="Recipient Email Address"
                          v-model="currentOrderUserInfo.shipping_details.email"
                          @input="updateValue"
                        >
                      </div>

                      <div class="form__item form__item--text">
                        <input
                          id="mailing-phone"
                          class="hide-arrows"
                          type="text"
                          maxlength="14"
                          pattern="\d*"
                          placeholder="Recipient Phone Number"
                          v-model="currentOrderUserInfo.shipping_details.phone"
                          @input="updateValue"
                        >
                      </div>

                      <div class="form__item form__item--text">
                        <input
                          id="mailing-address1" type="text"
                          :placeholder="'Shipping Address (Line 1)'"
                          v-model="currentOrderUserInfo.shipping_details.address.line1"
                          @input="updateValue"
                        >
                      </div>
                      <div class="form__item form__item--text">
                        <input
                          id="mailing-address2" type="text"
                          :placeholder="'Address (Line 2)'"
                          v-model="currentOrderUserInfo.shipping_details.address.line2"
                          @input="updateValue"
                        >
                      </div>
                      <div class="form__item form__item--text">
                        <input
                          id="mailing-city" type="text"
                          :placeholder="'City'"
                          v-model="currentOrderUserInfo.shipping_details.address.city"
                          @input="updateValue"
                        >
                      </div>

                      <div class="form__item form__item--select disabled">
                        <select
                          v-model="currentOrderUserInfo.shipping_details.address.state"
                          @input="updateValue"
                          @change="changedShipping"
                        >
                          <option
                            v-for="(item, index) in usStates"
                            :value="item.abbreviation"
                            :selected="inputValue.shipping_state"
                            :key="index"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                      <div class="form__item form__item--text disabled">
                        <input
                          id="mailing-zip"
                          class="hide-arrows"
                          type="text"
                          pattern="\d*"
                          maxlength="12"
                          placeholder="Zip code"
                          v-model="currentOrderUserInfo.shipping_details.address.postal_code"
                          @input="updateValue"
                          @change="changedShipping"
                        >
                      </div>
                    </div >
                  </div>
                  <p class="checkout__note">
                    Friendly 'fit' reminder: always double-check your measurements before ordering!
                  </p>

                  <div class="form" ref="errorMessageRefShippingAddress">
                    <transition name="warning-slide-down" mode="out-in">
                      <div
                        v-if="errorMessages"
                        v-html="errorMessages"
                        id="stripe-error-message"
                        class="form warning"
                      />
                    </transition>
                  </div>
                  <button :disabled="paymentBtn" @click="amountCheck" class="primary block customGreenBTN checkout__cta" @click.prevent="atStripeJSProcessOrder">
                    Make Payment
                  </button>
                </div>
              </div>
            </div>
          </footer>
        </section>
      </form>
      <Loader :visible="loading" fixed blocking :message="loadingMessage" />
    </div>
    <Modal
      v-if="confirmationModal.shown"
      centered
      confirmLabel="Yes"
      cancelLabel="No"
      @confirm="confirmUserActionModal(true)"
      @cancel="confirmUserActionModal(false)"
      @close="confirmationModal.shown = false"
      :fromTimeMixPopup=true
    >
      <template v-slot:header>
        Confirmation
      </template>
      <template v-slot:body>
        <p>Are you sure you want to delete this item?</p>
      </template>
    </Modal>
  </main>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { getFunctions, httpsCallable } from 'firebase/functions'
import Loader from '@/components/Loader'
import ResultsItems from '../components/results/ResultsItems'
import Modal from '@/components/Modal'
import { getStateFromZipUS } from '@/assets/scripts/helpers'
import router from '@/router'
const cloudFunction = getFunctions()
export default {
  name: 'Checkout',
  components: {
    Loader,
    Modal
  },
  data () {
    return {
      inputValue: {
        shipping_name: null,
        shipping_address1: null,
        shipping_address2: null,
        shipping_city: null,
        shipping_state: null,
        shipping_zip: null,
        shipping_email: '',
        shipping_phone: null
      },
      isBillingAddressValid: false,
      checkPhone: {
        isValid: false
      },
      discount: 0,
      shippingToggles: {},
      shippingCost: null,
      shipping: 100.00,
      taxes: null,
      loading: false,
      paymentBtn: false,
      loadingPrice: false,
      loadingMessage: null,
      localShippingInfo: {},
      localBillingInfo: {},
      currentOrderComplete: false,
      currentOrderUserInfo: {
        stripeUser: {
          stripeCustomerBillingName: null,
          stripeCustomerEmail: null,
          stripeCustomerId: null,
          stripePurchaseMethodId: null,
          stripePaymentMethodType: null
        },
        shipping_details: {
          name: '',
          email: '',
          phone: '',
          address: {
            city: '',
            // country: this.stripeCustomerPaymentMethods.data[lastIndex].billing_details.address.country || 'US',
            line1: '',
            line2: '',
            postal_code: '',
            state: ''
          }
        }
      },
      areInputValuesValid: false,
      errorMessages: '',

      stripeClientElements: {},
      stripeJS_elements: {},
      stripeJS_processOrder: {},
      stripeJS_confirmPayment: {},
      stripeCustomerPaymentMethods: {},
      stripeCustomerPaymentMethodCurrent: {},

      fromStripeCurrentOrder: {},
      existingOrder: null,
      quantityUpdateTimeout: null,
      confirmationModal: {
        shown: false,
        isWaiting: false,
        isConfirmed: false,
        itemIndex: null
      },
      showWhiteGloveModal: false,
      showStandardShippingModal: false,
      shownStandardShippingDescription: '',
      shownWhiteGloveShippingDescription: '',
      successURL: '',
      cancelURL: '',
      totalQuantity: 0
    }
  },
  computed: {
    ...mapGetters([
      'userProfile',
      'currentUser',
      'chosenFurnitureData',
      'targetSurvey'
    ]),
    ...mapState(['usStates']),

    orderUserInfoComputed () {
      return JSON.stringify(this.currentOrderUserInfo)
    },

    subtotal () {
      let output = 0
      if (!this.chosenFurnitureData) return output
      this.chosenFurnitureData.forEach((item) => {
        output += (item.itemPrice || 0) * item.quantity
      })
      return output
    },

    total () {
      return (this.subtotal || 0) - (this.discount || 0) + (this.shippingCost || 0) + (this.taxes || 0)
    },

    /**
     * Should the white glove service toggle be enabled?
     * If no items are eligible, them it shouldn't be.
     */
    whiteGloveServiceApplicable () {
      let applicable = false
      if (!this.chosenFurnitureData) return false
      this.chosenFurnitureData.forEach((item) => {
        if (item.shipping.whiteGlovePrice) applicable = true
      })
      return applicable
    }
  },
  methods: {
    router () {
      return router
    },
    ...mapActions([
      'getTargetSurvey',
      'getSelection',
      'getChosenFurnitureData',
      'updateFirestoreUserProfile',
      'updateFirestoreCurrentQuiz'
    ]),
    ...mapMutations([
      'setCompletedOrder',
      'updateUserProfile',
      'setGlobalError'
    ]),

    updateValue (e) {
      this.$emit('update:modelValue', this.inputValue)
    },
    close () {
      this.showStandardShippingModal = false
    },
    closeWhiteGlove () {
      this.showWhiteGloveModal = false
    },
    changedShipping () {
      this.atStripeUpdateOrder()
    },

    amountCheck () {
      if (this.paymentBtn === false) {
        return false
      } else {
        return true
      }
    },
    /**
     * Compares the chosenFurnitureData array against the line items of the Stripe order
     * to see if the user has updated their chosen furniture since the order was created.
     */
    chosenFurnitureDiffersFromOrder () {
      let differs = false
      this.chosenFurnitureData.forEach((item, index) => {
        const targetOrderLineItem = this.fromStripeCurrentOrder.data.line_items.data[index]
        if (item.recordId !== targetOrderLineItem?.product) {
          differs = true
        }
      })
      return differs
    },

    /**
     * Whenever the quantity is updated, trigger an update to the Stripe order.
     * Note that this function includes a timeout so that there isn't rapid retriggering
     * of the Stripe Order update, as the operation can take some time to complete.
     */
    quantityUpdated (itemIndex, itemQuantity) {
      this.confirmationModal.itemIndex = itemIndex
      // Reset the timeout if it exists
      clearTimeout(this.quantityUpdateTimeout)
      // If the quantity goes below 1, show the modal
      if (itemQuantity < 1) {
        this.confirmationModal.shown = true
      } else if (itemQuantity >= 1) {
        this.atStripeUpdateOrder()
        // this.quantityUpdateTimeout = setTimeout(() => {
        // }, 300)
      }
    },

    confirmUserActionModal (userResponse) {
      this.confirmationModal.isConfirmed = userResponse
      if (userResponse) {
        this.chosenFurnitureData[this.confirmationModal.itemIndex].quantity = 0 // handles negative numbers edge case
        this.atStripeUpdateOrder('removeLineItem', this.confirmationModal.itemIndex)
      }
      this.confirmationModal.shown = false
    },

    formatCurrency (number) {
      if (!number && number !== 0) {
        return '...'
      }

      const currency = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2
      })

      return currency.format(number)
    },

    // using the customerCustomerId get the payment methods with the last 4 digits of CC here.
    async atStripeGetCustomerPaymentMethods () { // ORDERS STEP 0 Init (Mount)
      this.loading = true

      try {
        const result = await httpsCallable(cloudFunction, 'sendStripeGetCustomerPaymentMethods')()

        this.stripeCustomerPaymentMethods = result.data

        this.stripeCustomerPaymentMethodCurrent = Object.keys(this.stripeCustomerPaymentMethods.data).length - 1 // last saved CC is the one used, unless changed by user.

        // Note the last Payment Method that the user entered is what gets populated in Billing Address,
        // it's also what is saved in the Firebase.userProfile & Vuex.userProfile. The billing details for other cards are saved in Stripe.

        // TODO: move this to updateCurrentOrderUserInfo() Initialize currentOrderUserInfo
        this.currentOrderUserInfo = {
          stripeUser: {
            stripeCustomerBillingName: this.userProfile.billing_name,
            stripeCustomerEmail: this.userProfile.email_address,
            stripeCustomerId: this.userProfile.stripeCustomerId,
            stripePurchaseMethodId: this.userProfile.stripePurchaseMethodId,
            stripeCustomerPaymentMethodCurrent: this.stripeCustomerPaymentMethodCurrent
          },
          shipping_details: {
            name: this.userProfile.shipping_name || this.userProfile.first_name || '',
            email: this.userProfile.shipping_email || this.userProfile.email_address || '',
            phone: this.userProfile.shipping_phone || '',
            address: {
              city: this.userProfile.shipping_city || '',
              country: this.userProfile.shipping_country || 'US',
              line1: this.userProfile.shipping_address1 || '',
              line2: this.userProfile.shipping_address2 || '',
              postal_code: this.targetSurvey.projectAddress.zip || this.userProfile.shipping_zip || '',
              state: this.targetSurvey.projectAddress.state || getStateFromZipUS(this.targetSurvey.projectAddress.zip) || this.userProfile.shipping_state || null
            }
          }
        }

        // shipping_details: this.stripeCustomerPaymentMethods.data[this.stripeCustomerPaymentMethodCurrent]?.shipping_details,
        // if (this.stripeCustomerPaymentMethods.data[this.stripeCustomerPaymentMethodCurrent].shipping_details) {
        //   this.currentOrderUserInfo = { ...this.currentOrderInfo, ...{ shipping_details: this.stripeCustomerPaymentMethods.data[this.stripeCustomerPaymentMethodCurrent]?.shipping_details } }
        // }

        return result
      } catch (error) {
        this.loading = false

        return error
      }
    },

    async atStripeCreateOrder () { // ORDERS STEP 1 Init (Mount)
      this.loadingMessage = 'Creating your order...'

      getStateFromZipUS(this.currentOrderUserInfo.shipping_details.address.postal_code || this.userProfile.shipping_zip)

      const createOrderPayload = {
        expand: ['line_items'],
        line_items: this.chosenFurnitureData,
        whiteGloveStatus: this.shippingToggles,
        selectionsId: this.$route.params.selectionId,
        shipping_details: this.currentOrderUserInfo.shipping_details,
        metadata: {
          selectionsId: this.$route.params.selectionId,
          amount_total: 0,
          amount_subtotal: 0,
          amount_shipping: 0
        }
      }

      try {
        const result = await httpsCallable(cloudFunction, 'sendStripeCreateOrder')(createOrderPayload)

        if (result?.data?.statusCode === 400 || ResultsItems?.statusCode === 500) {
          this.errorMessages = 'An Error Occurred Updating Your Order'
        }
        this.applyOrderToUi(result)
        this.loadingMessage = 'Finishing up...'
        // Get the Stripe order details in order to build out the payment UI
        // await this.atStripeRetrieveOrder(this.$route.params.selectionId)
        // this function use for update stripe order...
        // await this.atStripeUpdateOrder()
        return result
      } catch (err) {
        // this.setGlobalError('Sorry, an error occurred when creating your order.')
        return err
      }
    },

    /**
     * Once a Stripe order payload is received, use this to apply the values to the UI
     */
    applyOrderToUi (payload) {
      if (
        payload.data?.metadata?.shippingStatus &&
        payload.data?.metadata?.shippingStatus !== JSON.stringify(this.shippingToggles)
      ) {
        // Metadata saved on the order is stringified JSON, so we'll need to convert to object
        // We're also merging objects, just in case the user has added a new item
        this.shippingToggles = {
          ...this.shippingToggles,
          ...JSON.parse(payload.data.metadata.shippingStatus)
        }
      }

      const totalDetails = payload.data?.total_details
      if (totalDetails) {
        // Apply discounts, taxes, shipping, etc... to UI
        if (totalDetails.amount_discount || totalDetails.amount_discount === 0) {
          const amountDiscount = totalDetails.amount_discount / 100

          this.discount = amountDiscount
        }

        // Apply auto calculated taxes based on location provided in beginning of survey
        if (totalDetails.amount_tax || totalDetails.amount_tax === 0) {
          const amountTax = totalDetails.amount_tax / 100

          this.taxes = amountTax
        }
      }

      // const totalMetadata = payload.data?.metadata
      if (payload.data?.shipping_cost?.shipping_rate_data?.fixed_amount) {
        // Apply shipping cost
        const shippingAmount = payload.data?.shipping_cost?.shipping_rate_data?.fixed_amount.amount
        if (shippingAmount || shippingAmount === 0) {
          const amountShipping = shippingAmount / 100

          this.shippingCost = amountShipping
        }
      }
    },

    async atStripeRetrieveOrder (payload) { // ORDERS STEP 3 Init (Mount) & User Update Event
      await httpsCallable(cloudFunction, 'sendStripeRetrieveOrder')(payload).then((result) => {
        this.fromStripeCurrentOrder = result

        if (result?.data?.statusCode === 400 || ResultsItems.statusCode === 500) {
          if (result.data.rawType === 'invalid_request_error' && result.data.raw.code === 'resource_missing') {
            this.errorMessages = result.data.raw.message

            this.loading = false
          } else if (result.data.rawType === 'invalid_request_error') {
            this.errorMessages = 'Please check your billing address.  The State/Province does not match your Credit Card\'s Country.'

            this.loading = false
            return false
          } else {
            return false
          }
        } else if (result?.data.status === 'complete') {
          this.errorMessages = 'This Order is complete!'
          this.currentOrderComplete = true
        }

        // Save Line Items with Order Data from Payload
        // this.fromStripeCurrentOrder.data = {
        //   ...this.fromStripeCurrentOrder.data,
        //   ...{ line_items: result.data.line_items }
        // }
        //
        // // We'll also associate the line item ID with each piece of furniture
        // // This will be important when we go to update the order
        // result.data.line_items.data.forEach((item, index) => {
        //   this.chosenFurnitureData[index].lineItemId = item.id
        // })
        this.applyOrderToUi(result)
        // this.atStripeJSCreateElements(result)

        return result
      })
    },

    /**
     * Used to create the Stripe CC input elements iframe.
     */

    /**
     * Updates an existing Stripe order based the target action type, i.e. updateWhiteGloveShipping
     * This function is called when the user changes at item's shipping type,
     * quantities are adjusted, etc... so that updated taxes can be calculated.
     */
    async atStripeUpdateOrder (actionType, actionData) {
      this.loading = true
      this.loadingMessage = 'Updating your order...'

      // use this to update quantity, billing, shipping and paymentMethodId with currentOrderUserInfo

      const updateOrderActionPayload = {
        orderId: this.fromStripeCurrentOrder?.data?.metadata?.customerOrderId,
        selectionId: this.$route.params.selectionId,
        line_items: this?.chosenFurnitureData,
        shipping_details: this?.currentOrderUserInfo?.shipping_details,
        expand: ['line_items'],
        actionType,
        actionData,
        whiteGloveStatus: this?.shippingToggles,
        metadata: {
          selectionsId: this.$route.params.selectionId,
          amount_total: 0,
          amount_subtotal: 0,
          amount_shipping: 0
        }
      }
      var totalAmounts = []
      for (const index in updateOrderActionPayload.line_items) {
        const priceInDollars = (updateOrderActionPayload.line_items[index].itemPrice) ? (updateOrderActionPayload.line_items[index].itemPrice * updateOrderActionPayload.line_items[index].quantity) : 1
        const priceInCents = Math.round(priceInDollars * 100)
        totalAmounts.push(priceInCents)
      }
      const totalitemcost = totalAmounts.reduce((partialSum, a) => partialSum + a, 0)
      try {
        if (totalitemcost > 0) {
          const result = await httpsCallable(cloudFunction, 'sendStripeUpdateOrder')(updateOrderActionPayload)

          if (result.data.statusCode === 400 || result.data.rawType === 'invalid_request_error') {
            if (result.data.rawType === 'invalid_request_error') {
              this.errorMessages = 'This order may already be complete. Please check your <a href="/orders" style="text-decoration: underline">order status</a> or start a new survey. Please <a href="/support" style="text-decoration: underline">contact support</a> if there are any other issues.'
            }

            this.loading = false
            return false
          }
          // Finally, once we've confirmed the order is updated, remove the furniture from the UI
          if (actionType === 'removeLineItem') {
            localStorage.removeItem('product_item_' + this.chosenFurnitureData[actionData].recordId)
            this.chosenFurnitureData.splice(actionData, 1)
          }
          this.paymentBtn = false
          // await this.atStripeRetrieveOrder(this.$route.params.selectionId)
          this.applyOrderToUi(result)
          this.loading = false

          return result
        } else {
          alert('Please Add Quantity.')

          this.loading = false
          this.paymentBtn = true
          return 'Please Add Quantity.'
        }
      } catch (error) {
        this.loading = false
        return error
      }
    },

    /**
     * This function is called once the user fills out the CC info and chooses to make payment.
     * It is the final step in the purchase flow.
     */
    async atStripeJSProcessOrder () {
      this.loading = true
      this.loadingMessage = 'Processing payment...'
      await this.validateInputs('processOrder')

      if (!this.areInputValuesValid) {
        this.loading = false
        return false
      }

      // const shippingState = getStateFromZipUS(this.currentOrderUserInfo.shipping_details.address.postal_code || this.userProfile.shipping_zip)
      const finalPayload = {
        expand: ['line_items'],
        line_items: this.chosenFurnitureData,
        whiteGloveStatus: this.shippingToggles,
        selectionsId: this.$route.params.selectionId,
        shipping_details: this.currentOrderUserInfo.shipping_details,
        metadata: {
          selectionsId: this.$route.params.selectionId,
          amount_total: 0,
          amount_subtotal: 0,
          amount_shipping: 0
        },
        successUrl: this.successURL,
        cancelUrl: this.cancelURL
      }

      try {
        this.loadingMessage = 'Finishing up...'
        const finalResult = await httpsCallable(cloudFunction, 'updateStripeOrderBySelection')(finalPayload)

        if (finalResult?.data?.statusCode === 400 || finalResult?.statusCode === 500) {
          this.errorMessages = 'An Error Occurred Updating Your Order'
        }

        if (this.$gtm?.enabled()) {
          this.$gtm.trackEvent({
            event: 'Furniture payment init',
            category: 'Payment',
            action: 'click',
            value: finalResult?.data?.amount_total
          })
        }

        window.location.assign(finalResult.data.url)
        return finalResult
      } catch (err) {
        this.setGlobalError('Sorry, an error occurred when creating your order.')
        this.loading = false
        return err
      }

      // try {
      //
      //   // await this.handlePayment()
      //   await httpsCallable(cloudFunction, 'updateStripeOrderBySelection')({
      //     selectionId: this.$route.params.selectionId,
      //     updatePayload: {
      //       shipping_details: this.currentOrderUserInfo.shipping_details
      //     }
      //   })
      // } catch (err) {
      //
      //   this.setGlobalError('Sorry, there was an error updating your order\'s shipping info.')
      //   this.loading = false
      //   return false
      // }

      // const elements = this.stripeClientElements
      // try {
      //   const response = await this.stripeJS_confirmPayment({
      //     elements,
      //     redirect: 'if_required',
      //     confirmParams: {
      //       return_url: `${process.env.VUE_APP_ROOT_URL}/checkout/${this.$route.params.selectionId}`
      //     }
      //   })
      //

      //   if (response.error) {
      //     this.loading = false
      //     const testErrorString = 'You must provide enough location information to accurately determine tax rates'
      //

      //     // Now the order is in a submitted status... so we can't update it.
      //     // So we'll need to create a new one...
      //     // This is a temporary fix until we have a better solution (like a second screen).
      //     setTimeout(async () => {
      //       await this.atStripeCreateOrder()
      //     }, 3000) // Timeout is just so the user has some time to read the error message(s)

      //     if (response.error.message.indexOf(testErrorString) > -1) {
      //
      //       this.errorMessages = 'Looks like your Shipping State and Zip Code are invalid, please email INFO@TOTREE.CO or call (914) 340-4440 to update it to the correct location.'
      //       return false
      //     } else {
      //       this.errorMessages = response.error.message
      //
      //       return false
      //     }
      //   } else {
      //
      //     response.paymentIntent.id = `${this.currentUser.uid.slice(-5)}-${this.$route.params.selectionId.slice(-5)}`
      //     this.setCompletedOrder(response.paymentIntent)
      //     await this.saveUserAddresses()
      //     this.$router.push('/purchase-success')
      //   }
      //   this.loading = false
      // } catch (error) {
      //

      //   if (error.code === 'order_submit_invalid_status') {
      //     this.errorMessages = 'This order was already processed. You cannot purchase this order again.'
      //   }
      // }
    },

    async validateInputs (actionType) {
      this.areInputValuesValid = false

      if (this.validateShippingInputs()) {
        this.areInputValuesValid = true
        this.atStripeUpdateCustomer()
        if (actionType !== 'processOrder') {
          await this.atStripeUpdateOrder()
        }
      } else {

      }
    },

    async saveUserAddresses () {
      const savedAddressData = {
        shipping_city: this.currentOrderUserInfo.shipping_details.address.city || '',
        // shipping_country: this.currentOrderUserInfo.shipping_details.address.country || 'US',
        shipping_address1: this.currentOrderUserInfo.shipping_details.address.line1 || '',
        shipping_address2: this.currentOrderUserInfo.shipping_details.address.line2 || '',
        shipping_zip: this.currentOrderUserInfo.shipping_details.address.postal_code || this.userProfile.shipping_zip || '',
        shipping_state: this.currentOrderUserInfo.shipping_details.address.state || '',
        shipping_name: this.currentOrderUserInfo.shipping_details.name || '',
        shipping_phone: this.currentOrderUserInfo.shipping_details.phone || '',
        shipping_email: this.currentOrderUserInfo.shipping_details.email || ''
      }

      this.updateUserProfile(savedAddressData)
      this.updateFirestoreUserProfile(savedAddressData)

      var projectAddress = {
        project_address_city: this.currentOrderUserInfo.shipping_details.address.city,
        project_address_line_1: this.currentOrderUserInfo.shipping_details.address.line1,
        project_address_line_2: this.currentOrderUserInfo.shipping_details.address.line2,
        project_address_zip: this.currentOrderUserInfo.shipping_details.address.postal_code,
        project_address_state: this.currentOrderUserInfo.shipping_details.address.state,
        project_address_recipient_name: this.currentOrderUserInfo.shipping_details.name,
        project_address_recipient_email: this.currentOrderUserInfo.shipping_details.email,
        project_address_recipient_phone: this.currentOrderUserInfo.shipping_details.phone,
        zip: this.targetSurvey.projectAddress.zip
      }

      this.updateFirestoreCurrentQuiz({ projectAddress })
    },
    validateShippingInputs () {
      this.areInputValuesValid = false

      // Shipping Address
      if (!this.currentOrderUserInfo.shipping_details?.name) {
        this.errorMessages = 'Please update your Recipient Name'
        this.$refs.errorMessageRefShippingAddress.scrollIntoView({ behavior: 'smooth' })

        return false
      } else if (!this.currentOrderUserInfo.shipping_details?.address?.line1) {
        this.errorMessages = 'Please update your Shipping Address (Line 1)'
        this.$refs.errorMessageRefShippingAddress.scrollIntoView({ behavior: 'smooth' })

        return false
      } else if (!this.currentOrderUserInfo.shipping_details?.address?.city) {
        this.errorMessages = 'Please update your Shipping City'
        this.$refs.errorMessageRefShippingAddress.scrollIntoView({ behavior: 'smooth' })

        return false
      } else if (!this.currentOrderUserInfo.shipping_details?.address?.state) {
        this.errorMessages = 'Please update your Shipping State'
        this.$refs.errorMessageRefShippingAddress.scrollIntoView({ behavior: 'smooth' })

        return false
      } else if (!this.currentOrderUserInfo.shipping_details?.address?.postal_code) {
        this.errorMessages = 'Please update your Shipping Zip'
        this.$refs.errorMessageRefShippingAddress.scrollIntoView({ behavior: 'smooth' })

        return false
      } else if (!this.currentOrderUserInfo.shipping_details?.phone) {
        this.errorMessages = this.checkPhone.message || 'Please update your Recipient Phone'
        this.$refs.errorMessageRefShippingAddress.scrollIntoView({ behavior: 'smooth' })

        return false
      } else if (!this.currentOrderUserInfo.shipping_details) {
        this.errorMessages = 'Please check your Shipping Addresses Information.'

        this.$refs.errorMessageRefShippingAddress.scrollIntoView({ behavior: 'smooth' })

        return false
      } else {
        this.errorMessages = ''

        return true
      }
    },

    async syncBillingShippingInfo () {
      // Update shipping on Stripe, UserProfile and Vue save for user to update later.
      const shippingData = this.currentOrderUserInfo.shipping_details

      this.currentOrderUserInfo.billing_details = Object.assign({}, shippingData)
    },

    async atStripeUpdateCustomer () {
      const stripePayload = {
        name: this.currentOrderUserInfo.stripeUser.stripeCustomerBillingName, // || this.currentOrderUserInfo.billing_details?.name, TEST: Remove Billing Inputs
        email: this.userProfile.email_address || '',
        shipping: this.currentOrderUserInfo.shipping_details
      }

      const stripeCloudFunction = httpsCallable(cloudFunction, 'sendStripeUpdateCustomer')
      await stripeCloudFunction(stripePayload).then(async (result) => {
        if (result.data?.code) {
          return false
        }

        this.saveUserAddresses()

        return result
      })

      return stripePayload
    },

    setStandardShippingModal (description) {
      this.shownStandardShippingDescription = description
      this.showStandardShippingModal = true
    },

    setWhiteGloveShippingModal (description) {
      this.shownWhiteGloveShippingDescription = description
      this.showWhiteGloveModal = true
    },

    /**
     * Triggered whenever the user changes an item's shipping type, either standard or white-glove
     */
    async updateWhiteGloveShipping (item) {
      this.loading = true
      this.loadingMessage = 'Updating order...'
      // this.getChosenFurnitureData(this.$route.params.selectionId)
      // When an item uses flat rate vendor shipping,
      // all items from the same vendor should share that shipping type

      if (this.shippingToggles[item.recordId] === 'standard') {
        this.shippingToggles[item.recordId] = 'white-glove'
      } else {
        this.shippingToggles[item.recordId] = 'standard'
      }
      if (item.vendor && (item.vendor.shippingRateType === 'flat' || item.vendor.shippingRateType === 'flat rate')) {
        // Get the shipping state of the changed item
        const shippingType = this.shippingToggles[item.recordId]

        // Loop through each furniture item and update the shipping type to match where applicable
        this.chosenFurnitureData.forEach((furnitureItem) => {
          if (
            furnitureItem.vendor &&
            furnitureItem.vendor.name === item.vendor.name &&
            furnitureItem.shipping &&
            furnitureItem.shipping.whiteGlovePrice
          ) {
            this.shippingToggles[furnitureItem.recordId] = shippingType
          }
        })
      }
      const updateOrderActionPayload = {
        orderId: this?.fromStripeCurrentOrder?.data?.metadata?.customerOrderId,
        selectionId: this?.$route?.params?.selectionId,
        line_items: this?.chosenFurnitureData,
        shipping_details: this?.currentOrderUserInfo?.shipping_details,
        expand: ['line_items'],
        actionType: 'updateWhiteGloveShipping',
        whiteGloveStatus: this.shippingToggles,
        metadata: {
          selectionsId: this.$route?.params?.selectionId,
          amount_total: 0,
          amount_subtotal: 0,
          amount_shipping: 0
        }
      }
      const res = await httpsCallable(cloudFunction, 'sendStripeUpdateOrder')(updateOrderActionPayload)

      if (res.data.statusCode === 400) {
        this.errorMessages = 'You are not eligible for White Glove Service or an error has occurred.'

        this.loading = false
        return false
      }
      await this.atStripeRetrieveOrder(this.$route.params.selectionId)
      this.loading = false
    },

    copyBillingToShipping () {
      this.currentOrderUserInfo.shipping_details.name = this.currentOrderUserInfo.stripeUser.stripeCustomerBillingName
      this.currentOrderUserInfo.shipping_details.address.line1 = this.currentOrderUserInfo.billing_details.address.line1
      this.currentOrderUserInfo.shipping_details.address.line2 = this.currentOrderUserInfo.billing_details.address.line2
      this.currentOrderUserInfo.shipping_details.address.city = this.currentOrderUserInfo.billing_details.address.city
    }

  }, // END methods:{}

  async mounted () {
    this.successURL = `${process.env.VUE_APP_ROOT_URL}/purchase-success`
    this.cancelURL = window.location.href
    this.loading = true
    this.loadingMessage = 'Compiling selections...'
    // Get the associated survey, so we can load the associated projectAddress into the UI
    await this.getTargetSurvey({ id: this.$route.params.selectionId, setState: true })

    if (!this.targetSurvey?.projectAddress) {
      this.targetSurvey.projectAddress = {}
    }
    if (!this.targetSurvey?.projectAddress?.zip) {
      this.targetSurvey.projectAddress.zip = 10001
    }

    // To start, we're going to see if a Stripe order has already been created for this selection
    // DEBUG: Comment this out to reload checkout page if a order is created already without having to go through the entire quiz (but do not depend on this, going through the entire quiz is preferable)
    const selectionData = await this.getSelection(this.$route.params.selectionId)
    if (selectionData.stripeOrderId) {
      this.existingOrder = selectionData.stripeOrderId
    }

    await this.getChosenFurnitureData(this.$route.params.selectionId)

    this.atStripeGetCustomerPaymentMethods() // ORDER STEP 0
    // Default to quantity of 1 and standard shipping for each piece of furniture
    if (!this.chosenFurnitureData) return

    this.chosenFurnitureData.forEach((item) => {
      item.quantity = 1
      this.shippingToggles[item.recordId] = 'standard'
    })

    // If this Stripe order has already been created, we'll get it rather than creating a new one.
    if (this.existingOrder) {
      await this.atStripeRetrieveOrder(this.$route.params.selectionId)
      await this.atStripeUpdateOrder()
      // We'll need to see if the user has updated their chosen furniture since they were last here,
      // and update the order if necessary
      // if (this.chosenFurnitureDiffersFromOrder()) {
      //
      //   await this.atStripeUpdateOrder()
      // } else {
      //
      // }
      // Run order update just to make sure shipping/furniture hasn't been updated, etc...
    } else {
      // The order is created as the page is loaded, so the user can see discounts applied, prices, etc...
      await this.atStripeCreateOrder() // ORDER STEP 1
    }

    this.loading = false
  } // END mounted()
}
</script>

<style lang="scss" scoped>
  #stripe-error-message {
    text-align: center;
  }
  .hide-arrows::-webkit-outer-spin-button,
  .hide-arrows::-webkit-outer-spin-button:hover,
  .hide-arrows::-webkit-inner-spin-button,
  .hide-arrows::-webkit-inner-spin-button:hover {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .message-user-container {
    margin: 0 0 1rem 0;
  }
  .message-user {
    @include meta-text;
  }

  .form__item--text.disabled,
  .form__item--select.disabled {
    &::before {
      border: solid 1px gray;
      border-top: 0;
    }
  }

  input[type=text],
  input[type=phone],
  input[type=number],
  input[type=email],
  select {
    &:disabled {
      color: gray;
      opacity: 1.0;
    }
  }
  .checkout {
    position: relative;
    grid-column: span 12;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 0 var(--gutter);
    padding: calc(var(--leading) * 2) calc(var(--gutter) * 2) calc(var(--leading) * 2.5);
    color: $tt-green;

    .section {
      padding-top: 0;
    }

    @include breakpoint($s) {
      padding-left: 0;
      padding-right: 0;
    }

    &__project-name {
      grid-column: span 12;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: calc(var(--gutter) * 0.5);
    }

    .section__subheading--order-complete {
      text-align: center;
      grid-column: span 12;
      font-size: 1.2em;
    }

    .checkout__items__item__shipping {
      margin-top: .667rem;
    }

    .checkout__items__item__shipping__item {
      position: relative;
      padding-right: 3rem;
      padding-top: .1em;
      min-height: calc(var(--leading) * 1.5);

      input[type=radio] {
        left: auto;
        right: 1.5rem;
        width: 1rem;
        height: 1rem;
        top: 15%;

        &::after {
          top: .3em;
          right: .3em;
          bottom: .3em;
          left: .3em;
        }
      }

      .checkout__items__item__shipping__info {
        position: absolute;
        top: 15%;
        right: 0;
      }
    }

    .form {
      grid-column: span 12;
    }

    .checkout__items {
      grid-column: span 12;
      margin-top: var(--gutter);

      @include breakpoint($l-up) {
        padding-right: 18.75%;
        padding-left: 18.75%;
      }

      .checkout__items__item {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 0 var(--gutter);
        border-top: 1px solid $tt-green;
        padding: var(--gutter) 0;

        .checkout__items__item__image {
          grid-column: span 4;
          line-height: 0;

          @include breakpoint($m-up) {
            grid-column: span 2;
          }

          img {
            border-radius: .333rem;
          }
        }

        .checkout__items__item__details {
          grid-column: span 8;

          @include breakpoint($m-up) {
            grid-column: span 5;
          }

          .checkout__items__item__details__brand,
          .checkout__items__item__details__product-name {
            @include h3;
          }

          .checkout__items__item__details__product-name {
            margin-bottom: calc(var(--leading) / 2);
          }

          .checkout__items__item__details__status {
            @include meta-heading;
          }

          .checkout__items__item__details__controls {
            .form__item--text {
              float: left;
              width: 5rem;
            }

            button {
              padding: 0;
              vertical-align: top;
              background: none !important;
              border: 0 !important;
              height: auto;

              &:hover {
                color: #000;
              }
            }
          }
        }

        .checkout__items__item__price {
          grid-column: span 12;
          @include h3;
          text-align: right;

          @include breakpoint($m-up) {
            grid-column: span 5;
          }
        }
        .checkout__items__item__shipping {
          @include meta-heading;
        }
      }
    }

    .checkout__footer {
      grid-column: span 12;
      border-top: 1px solid $tt-green;

      @include breakpoint($l-up) {
        margin-right: 18.75%;
        margin-left: 18.75%;
      }

      &__inputs {
        border-top: 1px solid $tt-green;
        padding-top: calc(var(--gutter) * 2);
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: calc(var(--gutter) * 2);
        &__shipping, &__cc {
          flex: 1;
          min-width: 280px;
          // max-width: 400px;
        }
      }

      .checkout__footer__content {
        padding-top: var(--gutter);

        // @include breakpoint($m-up) {
        //   float: right;
        //   width: calc(50% - (var(--gutter) / 2));
        // }

        dl {
          @include clearfix;
          margin-bottom: var(--leading);
          @include h3;

          dt,
          dd {
            padding-bottom: calc(var(--leading) / 3);
            float: left;
            width: 50%;
          }

          dt {
            clear: both;
          }

          dd {
            text-align: right;
          }
        }

        .checkout__footer__total {
          margin-bottom: var(--leading-h1);
          @include h1;
          text-align: right;
        }
      }
    }
  }

  .checkout__subheading {
    @include h2;
    font-family: 'GT America Expanded';
    margin-bottom: 1rem;
  }
  .checkout__note {
    margin: 2rem 0;
  }

  .checkout .checkout__footer__inputs{
    width: 50%;
    margin: auto;
    border:none !important;
  }
  .form__item.form__item--text.quantity-cal{
    margin-right:15px;

  }
  .quantity-cal label{
    cursor:text !important;
  }
  .red {
    color:red;
  }

  @media (max-width: 768px) {
    .checkout .checkout__footer__inputs{
      width: 100%;
    }
  }

  .shipping-information {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-end;
    margin-bottom: 30px;
    &--shipping-description {
      display: flex;
      gap: 10px
    }
    &--row {
      width: 200px;
      display: flex;
      justify-content: space-between;
    }
  }
</style>
